'use-client';
import * as React from 'react';
import type { LinkBarPropsNoStyle } from '../../../LinkBar.types';
import styles from '../../style/ResponsiveLinkBar.scss';
import LinkBar from '../../LinkBar';

const ResponsiveLinkBar: React.FC<LinkBarPropsNoStyle> = props => {
  return <LinkBar {...props} styles={styles} />;
};

export default ResponsiveLinkBar;
